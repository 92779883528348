@font-face {
  font-family: "Montserrat-Reg";
  src: url(../fonts/Montserrat-Regular.ttf);
  font-display: swap
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url(../fonts/Montserrat-SemiBold.ttf);
  font-display: swap
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url(../fonts/Montserrat-Bold.ttf);
  font-display: swap
}

// Test code for code blocks

// code {
//   background-color: #2a2e36;
//   color: #e0e0e0;
//   border-radius: 4px;
//   padding: 3px 5px;
// }

.App {
  font-family: "Montserrat-Reg", sans-serif;
  color: #dfdfdf;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(200deg, #223347 0%, #19212b 100%);
}

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 
    inset -1px -1px 0px rgba(0, 0, 0, 0.05), 
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-track {
  width: 22px;
  background: #1a2733;
}

.display-none {
  display: none;
}

.back {
  z-index: 5;
  font-size: 1.2rem;
  color: #dfdfdf;
  top: 3.4rem;
  left: 3.4rem;
  display: flex;
  position: fixed;
  align-content: center;
  padding: 10px 5px 5px 10px;
  border-radius: 50px;
  opacity: 0.85;
  background-color: #121a22;
  transition: all 200ms ease;
  transform: translate(-50%, -50%);
  .arrow {
    opacity: 0.5;
    fill: #dfdfdf;
    height: 30px;
  }
}

.back:hover {
  padding: 11px 6px 6px 11px;
  .arrow:hover {
    height: 34px;
  }
}

.project-link {
  padding: 10px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 1vw;
  left: 24%;
  transform: translate(-50%, -50%);
  .arrow {
    transition: all 50ms ease;
    margin: 3px 0.7vmin 0px 0px;
    fill: #dfdfdf;
    width: 2.8vmin;
  }
  .proj {
    transition: all 50ms ease;
    color: #dfdfdf;
    font-size: 2.4vmin;
  }
}

.project-link:hover .arrow {
  width: 2.9vmin;
}

.project-link:hover .proj {
  font-size: 2.5vmin;
}

@media (max-width: 950px) {
  .project-link {
    left: 14%;
    .arrow {
      width: 3.5vmin;
    }
    .proj {
      font-size: 3.5vmin;
    }
  }
}