@mixin flex-col-cen {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin flex-col-spbtw {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}