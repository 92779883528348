@import "./mixins.scss";

a {
  text-decoration: none;
}
.wlcm-page-wrap {
  overflow: hidden;
}
.left-wrapper {
  float: left;
  width: 20%;
  height: 100vh;
}
.wlcm-content {
  font-family: "Montserrat-Bold", sans-serif;
  font-weight: bold;
  height: 100vh;
  width: 45vw;
  margin-left: 0vh;
  @include flex-col-cen;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
  color: #dfdfdf;
  img,
  .wlcm-ln1,
  .wlcm-ln2,
  .wlcm-ln3,
  .wlcm-ln3 {
    z-index: 3;
  }
  img {
    width: 170px;
    height: 170px;
  }
  .wlcm-ln1 {
    font-size: 4.7rem;
  }
  .wlcm-ln2 {
    font-size: 2rem;
  }
  .wlcm-ln3 {
    margin-top: 40px;
    font-size: 1rem;
  }
  .wlcm-ln4 {
    text-transform: none;
    color: #dfdfdf;
    opacity: 0.3;
    margin-top: 35px;
    font-size: 1rem;
  }
}
.logo-wrapper {
  overflow: hidden;
  position: relative;
  float: right;
  width: 80%;
  height: 100vh;
  top: 0;
  .logo {
    opacity: 0.15;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  .react {
    width: auto;
    height: 25vh;
    left: 26vh;
    top: 14vh;
  }
  .cSharp {
    width: auto;
    height: 27vh;
    left: 58vh;
    top: 7vh;
  }
  .vue {
    width: auto;
    height: 18vh;
    left: 90vh;
    top: 13vh;
  }
  .git {
    width: auto;
    height: 20vh;
    left: 116vh;
    top: 12vh;
  }
  .js {
    width: auto;
    height: 30vh;
    left: 149vh;
    top: 12vh;
  }
  .bootstrap {
    width: auto;
    height: 24vh;
    left: 50vh;
    top: 39vh;
  }
  .html {
    width: auto;
    height: 20vh;
    left: 78vh;
    top: 37vh;
  }
  .nodejs {
    width: auto;
    height: 19vh;
    left: 104vh;
    top: 35vh;
  }
  .ai {
    width: auto;
    height: 20vh;
    left: 130vh;
    top: 45vh;
  }
  .sql {
    width: auto;
    height: 20vh;
    left: 160vh;
    top: 43vh;
  }
  .sass {
    width: auto;
    height: 17vh;
    left: 61vh;
    top: 64vh;
  }
  .postman {
    width: auto;
    height: 22vh;
    left: 91vh;
    top: 62vh;
  }
  .css {
    width: auto;
    height: 20vh;
    left: 120vh;
    top: 72vh;
  }
  .rbs {
    width: auto;
    height: 40vh;
    left: 160vh;
    top: 80vh;
  }
  .github {
    width: auto;
    height: 20vh;
    top: 100vh;
    left: 15vh;
  }
  .ps {
    width: auto;
    height: 20vh;
    top: 96vh;
    left: 40vh;
  }
  .java {
    width: auto;
    height: 26vh;
    top: 90vh;
    left: 66vh;
  }
  .flutter {
    opacity: 0.15;
    width: auto;
    height: 28vh;
    top: 92vh;
    left: 94vh;
  }
  .dart {
    width: auto;
    height: 20vh;
    top: 97vh;
    left: 125vh;
  }
}

@media (max-width: 1550px) {
  .left-wrapper {
    width: 0%;
  }
  .wlcm-content {
    width: 40vw;
    margin-left: 8vh;
  }
}

@media (max-width: 850px) {
  .left-wrapper {
    width: 0%;
  }
  .wlcm-content {
    width: 52vw;
    margin-left: 10vh;
  }
}

@media (max-width: 607px) {
  .left-wrapper {
    width: 0%;
  }
  .wlcm-content {
    width: 60vw;
    margin-left: 10vh;
    img {
      width: 12rem;
      height: 12rem;
    }
    .wlcm-ln1 {
      font-size: 4.2rem;
    }
    .wlcm-ln2 {
      font-size: 1.6rem;
    }
  }
}

@media (max-width: 470px) {
  .left-wrapper {
    width: 0%;
  }
  .wlcm-content {
    width: 84vw;
    margin-left: 4.2vh;
    .wlcm-ln1 {
      font-size: 3.7rem;
    }
    .wlcm-ln2 {
      font-size: 1.3rem;
    }
    .wlcm-ln3 {
      margin-top: 48px;
      font-size: 1rem;
    }
  }
}
