@import "./mixins.scss";

h1,
h2 {
  font-family: "Montserrat-SemiBold", sans-serif;
  font-weight: bolder;
}
h2 {
  text-transform: uppercase;
  opacity: 0.65;
  font-size: 2.5rem;
  margin-bottom: 2.25rem;
}
.header-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vmin;
  background-color: #1a2733;
  width: 100%;
  margin-top: 10vmin;
}
.experience-container {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .experience-wrap {
    margin: 8vmin 5vmin 0;
    h3 {
      font-size: 27px;
      margin: 14vmin 0 8vmin;
    }
    .showcase-wrap {
      margin-bottom: 10vmin;
      height: 50%;
      width: 80vmin;
      .media-svg {
        height: 3rem;
        fill: #44b38b;
        transition: all 150ms ease;
      }
      .media-svg:hover {
        fill: #3fa17d;
      }
      .showcase {
        overflow: hidden;
        border-radius: 16px;
        transition: all 400ms ease;
      }
      .text {
        padding: 6px 12px 0;
        width: 97%;
        font-size: 1.1rem;
        .title-wrap {
          margin: 1vmin 0 2vmin 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .desc {
          text-align: justify;
          line-height: 1.4em;
          margin: 0 0 1.4em 0;
        }
        .features-wrap {
          display: flex;
          .features {
            margin-right: 50px;
          }
        }
        ul {
          list-style: none;
          padding-left: 1.5rem;
          align-items: center;
        }
        ul li::before {
          content: "●";
          font-size: 0.9rem;
          line-height: 2rem;
          color: #44b38b;
          margin-right: 0.8rem;
        }
      }
      .showcase:hover {
        border-radius: 20px;
      }
      .project-footer-wrap {
        margin: 3vmin 0 2vmin 0;
        background-color: #f2f5fb;
        padding: 10px 10px 10px 4px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        #logo-fr {
          height: 67px;
          width: auto;
        }
        #logo-pdi {
          height: 70px;
        }
      }
    }
  }
}
.proj-img {
  width: 100%;
  transition: all 300ms ease;
  transform: scale3d(1.035, 1.035, 1);
  transform-origin: center;
}
.proj-img:hover {
  transform: scale3d(1.02, 1.02, 1);
  transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

@media (max-width: 900px) {
  .experience-container {
    .experience-wrap {
      padding: 10vmin 0vmin;
      .showcase-wrap {
        height: 60%;
        width: 91vmin;
        .showcase {
          width: 100%;
        }
        .text {
          padding: 0.5vmin 0.4vmin 0 1vmin;
          width: 97%;
          font-size: 2.3vh;
          .title-wrap {
            margin: 0;
          }
        }
        .showcase:hover {
          border-radius: 20px;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .header-wrap {
    height: max-content;
    h2 {
      font-size: 8vmin;
      text-align: center;
      padding: 25px;
    }
  }
  .experience-container {
    height: 100%;
    .proj-wrap {
      height: 28vmin;
    }
    .experience-wrap {
      padding: 8vmin 0vmin;
      .showcase-wrap {
        height: 60%;
        width: 91vmin;
        .showcase {
          margin-left: -6.5vmin;
          width: 115%;
          border-radius: 0;
        }
        .text {
          padding: 3vmin 0.4vmin 1vmin;
          width: 97%;
          font-size: 2.3vh;
        }
        .project-footer-wrap {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}