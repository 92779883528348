@import "./mixins.scss";
@import "./variables.scss";

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(-2deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-4deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(-2deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(-3deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(-2deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-4deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(-3deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-4deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(-2deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(-3deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-4deg);
  }
}

.info-wrapper {
  overflow-x: hidden;
  height: 100%;
  position: relative;
  .left-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    float: left;
    width: 50%;
    .info-wrap {
      padding: 0 5.5vw;
      .info {
        display: flex;
        .img-div {
          position: relative;
          flex: 1 1 50%;
          .me {
            display: block;
            width: 100%;
            border-radius: 2vmin;
            transform: rotate(-4deg);
          }
          .me-angry {
            animation: shake 0.5s;
            animation-iteration-count: infinite;
          }
          .egg1 {
            color: #a5a5a51c;
            cursor: not-allowed;
            bottom: -8px;
            right: 0;
            position: absolute;
            font-size: 6px;
          }
        }
        .info-div {
          flex: 1 1 50%;
          padding-left: 4.5vmin;
          .info-content {
            @include flex-col-spbtw;
            height: 100%;
            .name {
              line-height: 3.5vw;
              text-transform: uppercase;
              .first {
                font-size: 3vw;
              }
              .last {
                font-size: 4vw;
              }
            }
            .email {
              color: #74a3c4;
              font-size: 1.5vw;
            }
            .email:hover {
              text-decoration: underline;
            }
            .media {
              @include flex(row, space-between, left);
              .media-link {
                position: relative;
                width: 3vw;
                height: 3vw;
              }
              .media-link::after {
                position: absolute;
                background: #dfdfdf;
                content: "";
                bottom: -6px;
                height: 2.5px;
                left: 0;
                width: 100%;
                transform: scaleX(0);
                transition: transform ease 0.1s;
                will-change: transform;
              }
              .media-link:hover::after {
                transform: scaleX(1);
              }
              .media-svg {
                fill: #dfdfdf;
              }
            }
          }
        }
      }
      .desc {
        text-align: justify;
        font-size: 1.2vw;
        margin-top: 4vw;
        p {
          .scrabble-link {
            color: #74a3c4;
            font-size: 20px;
          }
          .scrabble-link:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .stack-wrap {
    @include flex(column, center, center);
    float: right;
    width: 50%;
    height: 100vh;
    .frameworks-wrap,
    .libraries-wrap,
    .fundamentals-wrap,
    .backend,
    .version-control,
    .design-wrap {
      .fw,
      .react,
      .vue,
      .bootstrap,
      .rbs,
      .sass,
      .html,
      .css,
      .js,
      .typescript,
      .be,
      .sql,
      .nodejs,
      .github,
      .git,
      .figma,
      .ai {
        position: absolute;
        width: auto;
        height: 12vmin;
      }
      padding-top: 5vmin;
      height: 5vmin;
      width: 10px;
      position: relative;
    }
    .frameworks-wrap,
    .libraries-wrap,
    .fundamentals-wrap,
    .backend {
      margin-bottom: 7.7vmin;
    }
    .frameworks-wrap {
      margin-top: 8vmin;
      .fw {
        top: -6.5vmin;
        left: -25vmin;
        font-size: 7vmin;
      }
      .react {
        left: -11vmin;
        height: 14vmin;
      }
      .vue {
        left: 11vmin;
        height: 13vmin;
        padding-right: 0;
      }
    }
    .libraries-wrap {
      .lib {
        top: -4.5vmin;
        left: -10vmin;
      }
      .bootstrap {
        left: -19vmin;
      }
      .rbs {
        height: 12vmin;
      }
      .sass {
        height: 11vmin;
        left: 21vmin;
      }
    }
    .fundamentals-wrap {
      height: 8vmin;
      .funds {
        top: -7.5vmin;
        left: -39vmin;
        font-size: 7.2vmin;
      }
      .html,
      .css,
      .js,
      .typescript {
        height: 13vmin;
      }
      .html {
        left: -28.5vmin;
      }
      .css {
        left: -10vmin;
      }
      .js {
        left: 10vmin;
      }
      .typescript {
        left: 30vmin;
      }
    }
    .backend {
      height: 3vmin;
      .be {
        top: -5.4vmin;
        left: -16vmin;
      }
      .sql {
        left: -18vmin;
        height: 12vmin;
      }
      .nodejs {
        left: 18vmin;
        height: 12vmin;
      }
      .aws {
        left: 0vmin;
        height: 12vmin;
      }
    }
    .design-wrap {
      height: 9vmin;
      .design {
        font-size: 4.4vmin;
        top: 1.5vmin;
        left: -8.7vmin;
        width: 35vw;
      }
      .figma {
        left: -16vmin;
        height: 12vmin;
      }
      .ai {
        left: 16vmin;
        height: 10vmin;
      }
    }
    .version-control {
      height: 10vmin;
      .vc {
        font-size: 4.1vmin;
        top: 2vmin;
        left: -20.5vmin;
        width: 35vw;
      }
      .github {
        height: 11vmin;
        left: -27.5vmin;
      }
      .git {
        height: 11vmin;
        left: 27.5vmin;
      }
    }
    .stack-text {
      font-size: 5vmin;
      font-family: "Montserrat-SemiBold", sans-serif;
      font-weight: bolder;
      opacity: 0.08;
      z-index: 0;
      text-transform: uppercase;
      position: absolute;
      top: -20px;
    }
  }
}
.logo {
  transition: all 50ms ease;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.info-wrapper .stack-wrap .frameworks-wrap .react:hover {
  height: 14.8vmin;
}
.info-wrapper .stack-wrap .frameworks-wrap .vue:hover {
  height: 13.7vmin;
}
.info-wrapper .stack-wrap .libraries-wrap .logo:hover {
  height: 12.4vmin;
}
.info-wrapper .stack-wrap .fundamentals-wrap .logo:hover {
  height: 13.3vmin;
}
.info-wrapper .stack-wrap .backend .logo:hover {
  height: 12.5vmin;
}
.info-wrapper .stack-wrap .version-control .github:hover,
.info-wrapper .stack-wrap .version-control .git:hover,
  .info-wrapper .stack-wrap .libraries-wrap .sass:hover {
  height: 11.4vmin;
}
.info-wrapper .stack-wrap .design-wrap .figma:hover {
  height: 12.7vmin;
}
.info-wrapper .stack-wrap .design-wrap .ai:hover {
  height: 10.4vmin;
}

@media (max-width: 1430px) {
  .info-wrapper {
    height: 200vh;
    .left-wrap {
      float: left;
      width: 100%;
      .info-wrap {
        width: 70%;
        font-size: 1.5vmin;
        padding: 0 4vw;
        .info {
          .info-div {
            .info-content {
              .name {
                line-height: 5vw;
                .first {
                  font-size: 4vw;
                }
                .last {
                  font-size: 6.2vw;
                }
              }
              .email {
                font-size: 3vw;
              }
              .email:hover {
                text-decoration: underline;
              }
              .media {
                @include flex(row, space-between, left);
                .media-link {
                  position: relative;
                  width: 5vw;
                  height: 5vw;
                }
              }
            }
          }
        }
        .desc {
          font-size: 1.8vw;
          margin-top: 4vw;
        }
      }
    }
    .stack-wrap {
      float: left;
      width: 100%;
      .version-control {
        .vc {
          font-size: 4.1vmin;
          top: 25%;
          left: -21vmin;
          width: max-content;
        }
      }
      .design-wrap {
        .figma {
          height: 10vmin;
        }
        .ai {
          height: 9vmin;
        }
        .figma:hover {
          height: 10.8vmin;
        }
        .ai:hover {
          height: 9.6vmin;
        }
      }
    }
  }
  .project-link {
    left: 48.5%;
    .proj {
      font-size: 3vmin;
    }
  }
  .logo {
    height: 100%;
    z-index: 1;
  }
}

@media (max-width: 950px) {
  .info-wrapper {
    display: block;
    height: 100%;
    .left-wrap {
      float: left;
      height: 100vw;
      width: 100%;
      .info-wrap {
        width: 87%;
        .info {
          .info-div {
            .info-content {
              .name {
                line-height: 6vw;
                .first {
                  font-size: 5vw;
                }
                .last {
                  font-size: 7vw;
                }
              }
              .email {
                font-size: 3.6vw;
              }
              .media {
                .media-link {
                  width: 6vw;
                  height: 6vw;
                }
              }
            }
          }
        }
        .desc {
          font-size: 1.2rem;
        }
      }
    }
    .stack-wrap {
      height: 100vh;
    }
    .project-link {
      left: 89%;
      position: inherit;
      bottom: 0vmin;
    }
  }
}

@media (max-width: 746px) {
  .experience-page .experience-wrap .showcase-wrap .text .features-wrap {
    display: block !important;
  }
}

@media (max-width: 530px) {
  .info-wrapper {
    display: block;
    height: 100%;
    .left-wrap {
      float: left;
      margin-top: 22vmin;
      height: 100%;
      width: 100%;
      .info-wrap {
        width: 85%;
        .info {
          display: block;
          .info-div {
            height: 53vmin;
            padding: 0;
            margin: 7vmin 0;
            .info-content {
              .name {
                line-height: 10vw;
                .first {
                  font-size: 10vw;
                }
                .last {
                  font-size: 12vw;
                }
              }
              .email {
                font-size: 5.5vw;
              }
              .media {
                .media-link {
                  width: 11vw;
                  height: 11vw;
                }
              }
            }
          }
        }
        .desc {
          font-size: 1.4rem;
        }
      }
    }
    .stack-wrap {
      height: 100vw;
      margin: 25vmin 0;
      .stack-text {
        opacity: 0.15;
      }
      .frameworks-wrap,
      .libraries-wrap,
      .fundamentals-wrap {
        padding-bottom: 10vmin;
      }
      .frameworks-wrap {
        .fw {
          font-size: 8vmin;
          top: -10vmin;
        }
        .react {
          height: 19vmin;
        }
        .vue {
          height: 18vmin;
        }
      }
      .libraries-wrap {
        .lib {
          top: -7vmin;
          font-size: 7vmin;
        }
        .bootstrap {
          height: 14vmin;
        }
        .rbs {
          height: 14vmin;
        }
        .sass {
          height: 14vmin;
        }
      }
      .fundamentals-wrap {
        top: 1vmin;
        .funds {
          top: -10vmin;
          font-size: 8.5vmin;
        }
        .html,
        .css,
        .js,
        .typescript {
          top: 6vmin;
          height: 17vmin;
        }
      }
      .backend {
        top: 2.5vmin;
        .be {
          font-size: 6vmin;
          top: -8vmin;
          left: -17vmin;
        }
        .sql,
        .aws,
        .nodejs {
          height: 16vmin;
        }
        .sql {
          left: -21vmin;
        }
        .nodejs {
          left: 21vmin;
        }
      }
      .design-wrap {
        top: 4vmin;
        .design {
          font-size: 4.2vmin;
        }
        .figma {
          height: 13vmin;
        }
        .ai {
          height: 13vmin;
        }
      }
      .version-control {
        top: 14vmin;
        .vc {
          font-size: 3.9vmin;
          left: -20vmin;
          top: 3vmin;
        }
        .github {
          height: 14vmin;
          left: -30vmin;
        }
        .git {
          height: 15vmin;
          left: 30vmin;
        }
      }
    }
    .project-link {
      left: 85.4%;
      position: inherit;
      bottom: 0vmin;
      .arrow {
        width: 4.5vmin;
      }
      .proj {
        font-size: 4.5vmin;
      }
    }
  }
}
